<template>
  <Layout>
    <!-- <b-overlay :show="is_loading_chat"> -->
    <div class="card" style="min-height: 100vh;" v-if="core !== null && !is_loading_chat">
      <b-row class="bg-blue">
        <h1 v-text="getChatTitle"></h1>
      </b-row>
      <hr />
      <!-- begin chat messages -->
      <div class="chat-messages" ref="messageArea">
        <chatMessage
          v-for="message in chat_room_messages"
          :key="`message-${message.id}`"
          :message="message"
          v-bind="message"
        ></chatMessage>

        <b-alert show v-if="chat_room_messages.length <= 0">No Chat Messages</b-alert>
        <!-- Needs to keep context of which chat we are in -->
      </div>
      <!-- end chat messages -->
      <hr />
      <!-- begin chat input -->
      <!-- Its going to need to know the current room we are in -->
      <chatInput :roomId="this.current_chat_room"> </chatInput>
      <!-- end chat input -->
    </div>
    <!-- </b-overlay> -->

    <Loading v-else></Loading>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main';
// import store from "@/state/store";
import Loading from '@/components/widgets/loadingBlock.vue';
import chatMessage from '@/components/chat/chatMessage.vue';
import {
  settingsMethods,
  adminComputed,
  authMethods,
  menuMethods,
  chatComputed,
  chatMethods,
  levelComputed,
  levelMethods,
} from '@/state/helpers';

import chatInput from '@/components/chat/chatInput.vue';
import _ from 'lodash';

export default {
  components: {
    Layout,
    Loading,
    chatMessage,
    chatInput,
  },
  data() {
    return {};
  },
  computed: {
    ...adminComputed,
    ...levelComputed, // selected_user_id = null if nothing selected
    ...chatComputed,
    getCurrentUserProfileImage() {
      return this.core.avatar_src;
    },
    getChatUserProfileImage() {
      const chatRoom = this.getCurrentChatRoom;
      if (_.isEmpty(chatRoom)) {
        return false;
      }

      let user_object = this.all_users.find((user) => {
        return user.id == chatRoom?.linkable?.id;
      });
      return user_object.avatar_src;
    },
    getChatTitle() {
      // if team chat
      // if org chat
      // if 1on1 chat
      const chatRoom = this.getCurrentChatRoom;
      if (this.isGroupChat) {
        return `Organisational Chat > ${chatRoom?.linkable?.name ?? ''}`;
      } else {
        return this.core.id === chatRoom.linkable_id
          ? `Direct Chat > ${this.findUser(chatRoom.created_by_user_id).name}`
          : `Direct Chat > ${chatRoom?.linkable?.name ?? ''} ${chatRoom?.linkable?.middle_name ?? ''} ${chatRoom
              ?.linkable?.last_name ?? ''}`;
      }
    },
    getFullName() {
      // @todo should be the user we are chatting too
      return this.core.full_name;
    },
    isGroupChat() {
      return !this.getCurrentChatRoom?.is_direct ?? false;
    },
    groupChatTitle() {
      return `Organisational Chat`;
    },
  },
  methods: {
    ...settingsMethods,
    ...authMethods,
    ...menuMethods,
    ...chatMethods,
    ...levelMethods,
    findUser(userId) {
      let user_object = this.all_users.find((user) => {
        return user.id == userId;
      });
      return user_object;
    },
    handleClickOutsideContextMenu(event) {
      event.preventDefault();
      if (!this.is_visible_context_menu && !this.is_visible_emoji_picker) {
        // Check if the click occurred outside the context menu
        const isClickOutside = !event.target.closest('.b-popover');
        const isClickOnBadge = event.target.closest('.badge');
        if (isClickOutside && !isClickOnBadge) {
          // this.toggleContextMenuVisible();
          this.$root.$emit('bv::hide::popover');
        }
        return;
      }
      if (this.is_visible_context_menu && !this.is_visible_emoji_picker) {
        // Check if the click occurred outside the context menu
        const isClickOutside = !event.target.closest('.context-menu');
        if (isClickOutside) {
          this.toggleContextMenuVisible();
        }
      }

      if (this.is_visible_emoji_picker) {
        const isClickOutside = !event.target.closest('.emoji-invoker');
        if (isClickOutside) {
          if (this.is_visible_emoji_picker) {
            const isClickOnEmojiPicker = event.target.closest('.emoji-picker');
            if (isClickOnEmojiPicker) return;
            this.toggleEmojiPickerVisible();
          } else {
            this.toggleEmojiPickerVisible();
          }
        }
      }
    },
    scrollToBottom() {
      const messageArea = this.$refs.messageArea;
      if (messageArea) {
        messageArea.scrollTop = messageArea.scrollHeight;
      }
    },
  },
  watch: {
    selected_user_id: {
      immediate: false,
      //eslint-disable-next-line
      handler(newVal, oldVal) {
        console.log('SELECTED USER ID FIRING', { newVal, oldVal });
        // Don't open chats for currently logged in user
        if (this.core.id !== newVal && newVal !== null) {
          this.newChat({ userId: newVal, type: 'user' }).then(async (response) => {
            const channels = {
              new: `chat-room.${response.data.room_id}`,
              old: `chat-room.${this.previous_chat_room}`,
            };
            // Unsubscribe to old presence channel
            this.$echoManager.leaveChannel(channels.old);
            // Subscribe to the presence channel of the chat room
            this.$echoManager.subscribeToPresenceChannel(channels.new, {
              onHere: (users) => {
                console.log('ECHO::Users currently on the channel:', users);
                this.onHere(users);
              },
              onJoining: (user) => {
                console.log('ECHO::User joining:', user);

                this.readRoom(this.getCurrentChatRoom);
              },
              onLeaving: (user) => {
                //@todo this must update the online status
                console.log('ECHO::User leaving:', user);
                this.onLeaving(user);
              },
              onEvent: {
                ChatMessageSent: (data) => {
                  console.log('ECHO::Message sent:', data);
                  const { userId } = data;
                  // Fetch The Message if it's not from the user
                  if (this.core.id !== userId) {
                    // May have to check the rooms are the same too
                    this.onChatMessageSent(data);
                  }
                },
                ChatMessageRead: (data) => {
                  console.log('ECHO::Message read:', data);
                  this.onChatMessageRead(data);
                },
                ChatRoomRead: (data) => {
                  console.log('ECHO: Chat Room Read:', data);
                  this.onChatRoomRead(data.roomId);
                },
                ChatMessageDeleted: (data) => {
                  console.log('ECHO::Message deleted:', data);
                },
                ChatMessageReaction: (data) => {
                  this.onChatMessageReaction(data);
                  console.log('ECHO::Message reaction:', data);
                },
              },
            });
            return response;
          });
        }
      },
    },

    selected_team_id: {
      immediate: false,
      //eslint-disable-next-line
      handler(newVal, oldVal) {
        // No Team Selected -1
        if (newVal > 0) {
          this.newChat({ userId: newVal, type: 'team' }).then(async (response) => {
            const channels = {
              new: `chat-room.${response.data.room_id}`,
              old: `chat-room.${this.previous_chat_room}`,
            };
            // Unsubscribe to old presence channel
            this.$echoManager.leaveChannel(channels.old);
            // Subscribe to the presence channel of the chat room
            this.$echoManager.subscribeToPresenceChannel(channels.new, {
              onHere: (users) => {
                console.log('ECHO::Users currently on the channel:', users);
                this.onHere(users);
              },
              onJoining: (user) => {
                console.log('ECHO::User joining:', user);

                //@todo this must update the state
                this.readRoom(this.getCurrentChatRoom);
              },
              onLeaving: (user) => {
                //@todo this must update the online status
                console.log('ECHO::User leaving:', user);
                this.onLeaving(user);
              },
              onEvent: {
                ChatMessageSent: (data) => {
                  console.log('ECHO::Message sent:', data);
                  const { userId } = data;
                  // Fetch The Message if it's not from the user
                  if (this.core.id !== userId) {
                    // May have to check the rooms are the same too
                    this.onChatMessageSent(data);
                  }
                },
                ChatMessageRead: (data) => {
                  console.log('ECHO::Message read:', data);
                  this.onChatMessageRead(data);
                },
                ChatRoomRead: (data) => {
                  console.log('ECHO: Chat Room Read:', data);
                  this.onChatRoomRead(data.roomId);
                },
                ChatMessageDeleted: (data) => {
                  console.log('ECHO::Message deleted:', data);
                },
                ChatMessageReaction: (data) => {
                  this.onChatMessageReaction(data);
                  console.log('ECHO::Message reaction:', data);
                },
              },
            });
            return response;
            // await this.updateUserChatRooms();
          });
        }
        // this.loadUserSpecificDetails();
      },
    },

    current_level_id: {
      immediate: true,
      //eslint-disable-next-line
      handler(newVal, oldVal) {
        // No Team Selected -1
        if (newVal > 0) {
          this.newChat({ userId: newVal, type: 'level' }).then(async (response) => {
            const channels = {
              new: `chat-room.${response.data.room_id}`,
              old: `chat-room.${this.previous_chat_room}`,
            };
            // Unsubscribe to old presence channel
            this.$echoManager.leaveChannel(channels.old);
            // Subscribe to the presence channel of the chat room
            this.$echoManager.subscribeToPresenceChannel(channels.new, {
              onHere: (users) => {
                console.log('ECHO::Users currently on the channel:', users);
                this.onHere(users);
              },
              onJoining: (user) => {
                console.log('ECHO::User joining:', user, this.core.id);

                //@todo this must update the state
                this.readRoom(this.getCurrentChatRoom);
              },
              onLeaving: (user) => {
                //@todo this must update the online status
                console.log('ECHO::User leaving:', user);
                this.onLeaving(user);
              },
              onEvent: {
                ChatMessageSent: (data) => {
                  console.log('ECHO::Message sent:', data);
                  const { userId } = data;
                  // Fetch The Message if it's not from the user
                  if (this.core.id !== userId) {
                    // May have to check the rooms are the same too
                    this.onChatMessageSent(data);
                  }
                },
                ChatMessageRead: (data) => {
                  console.log('ECHO::Message read:', data);
                  this.onChatMessageRead(data);
                },
                ChatRoomRead: (data) => {
                  console.log('ECHO: Chat Room Read:', data);
                  this.onChatRoomRead(data.roomId);
                },
                ChatMessageDeleted: (data) => {
                  console.log('ECHO::Message deleted:', data);
                },
                ChatMessageReaction: (data) => {
                  this.onChatMessageReaction(data);
                  console.log('ECHO::Message reaction:', data);
                },
              },
            });
            return response;
          });
        }
      },
    },
    current_chat_room: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return false;
        }
        //eslint-disable-next-line
        this.updateCurrentChatRoomMessages(newVal).then((response) => {
          this.scrollToBottom();
        });
      },
    },
  },
  async mounted() {
    // we fetch the user chat rooms from the API on mount
    await this.getUserChatRooms().then(() => {
      this.setLoading(false);
    });
    document.addEventListener('click', this.handleClickOutsideContextMenu);
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutsideContextMenu);
  },
};
</script>
<!-- / calc 80% of window height maybe less for chat-messages window -->
<style lang="scss" scoped>
.chat-messages {
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 60vh;
  padding: 33px;
}
</style>
